/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import homeBackground from "../../assets/2025Spring/back.jpg";
import { TranslationOutlined } from "@ant-design/icons"
import { useTranslation, Trans, Translation } from 'react-i18next'
import cssStyle from "./index.module.css";
import { Button, Card, Checkbox, Col, Divider, Form, Input, Image, message, Row, Steps, Select } from "antd";
import axios from "axios"
import jwt, { JwtPayload } from "jsonwebtoken"
import homeQRcode from "../../assets/qrCode.png";
import Instruction from "../ins/instruction";
import Terms from "../ins/terms";
import { json } from "express";
const { Option } = Select;
export const Registry: React.FunctionComponent<{ token: string }> = ({ token }) => {
  let { t, i18n } = useTranslation()
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [link, setLink] = useState("");
  const [step, setStep] = useState(0);
  const [showIntro, ssIntro] = useState<boolean>(false);
  const [showTerm, ssTerm] = useState<boolean>(false);
  useEffect(
    () => {
      try {
        const ret = jwt.decode(token);
        if (ret) {
          const r = (ret as JwtPayload);
          if (r.name && r.id && r.email) {
            setStep(2);
            setName(r.name);
            setId(r.id);
            setEmail(r.email);
          }
        }
      }
      catch (e) {

      }
    }
    , [token])
  useEffect(
    () => {
      if (showIntro && showTerm) {
        ssTerm(false);
        ssIntro(false);
      }
    }
    , [showIntro, showTerm])

  return (
    <div style={{
      height: "100vh",
      width: "100vw",
      overflow: "hidden",
    }}>
      <div
        className={cssStyle.mainBackground}
        onMouseMove={
          (e) => {
            setX(e.clientX);
            setY(e.clientY);
          }
        }
        style={{
          backgroundImage: `url(${homeBackground})`,
          left: `calc(${x}px /40  - 100vw / 40)`,
          top: `calc(${y}px /40  - 100vh / 40)`,
        }}
      >
      </div>
      <div style={
        {
          width: "100vw",
          bottom: "0",
          position: "absolute"
        }
      }>

        <div style={{ margin: "0 auto", width: "fit-content" }}><a href="https://beian.miit.gov.cn/">京ICP备2021029154号-1</a></div>
      </div>

      <Row justify="center" align="middle" style={
        {
          width: "100vw",
          height: "100vh",
          position: "absolute"
        }
      }>
        <Col>
          <Card
            title={
              <>
                <Row>
                  <Col>
                    {t("pageTitle")}
                  </Col>
                  <Col style={{ paddingLeft: "15px" }}>
                    <Button icon={<TranslationOutlined />}
                      type="text"
                      onClick={() => {
                        i18n.changeLanguage(i18n.language === 'en' ? 'zh' : 'en')
                      }}
                    >
                      {t("language")}
                    </Button>
                  </Col>
                </Row>
              </>
            }
            style={{
              background: "rgba(255,255,255,.85)",
              maxWidth: "95vw"
            }}
            bodyStyle={{
              maxHeight: "80vh",
              overflow: "auto"
            }}
            bordered={false}
          >
            <Steps current={step} size="small" direction="vertical">
              <Steps.Step key="readme" title={t("steps.readme")} />
              <Steps.Step key="login" title={t("steps.login")} />
              <Steps.Step key="register" title={t("steps.register")} />
              <Steps.Step key="done" title={t("steps.done")} />
            </Steps>
            {step === 0 && !showIntro && !showTerm &&
              <Form
                autoComplete="off"
                name="basic"
                onFinish={() => { setStep(1) }}
              >
                <Form.Item name="readReadme" valuePropName="checked"
                  rules={[{
                    validator: (_, value) => new Promise((resolve, reject) => {
                      if (value) return resolve(null);
                      else return reject(new Error(t('read') + t('readme')));

                    })
                  }]}
                >
                  <Checkbox>
                    {t('check')}
                    <a onClick={() => { ssTerm(true) }}>{t('readme')}</a >
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  name="readInstruction" valuePropName="checked"
                  rules={[{
                    validator: (_, value) => new Promise((resolve, reject) => {
                      if (value) return resolve(null);
                      else return reject(new Error(t('read') + t('instruction')));

                    })
                  }]}
                >
                  <Checkbox>
                    {t('check')}
                    <a onClick={() => { ssIntro(true) }}>
                      {t('instruction')}
                    </a></Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t('next')}
                  </Button>
                </Form.Item>
              </Form>
            }

            {step === 0 && showIntro && !showTerm && <Instruction callback={() => { ssIntro(false) }} />}
            {step === 0 && showTerm && !showIntro && <Terms callback={() => { ssTerm(false) }} />}

            {step === 1 &&
              <Button type="primary" size="large" block style={{ margin: "0 auto" }} onClick={() => { window.location.href = "/api/friend" }}>
                {t("verify")}
              </Button>
            } {step === 2 &&
              <Form
                autoComplete="off"
                name="basic"
                onFinish={(data) => {
                  axios.post("/api/friend", { token, qqID: data.inputQQID, otherInfo: JSON.stringify(data) }).then(({ data }) => {
                    if (data.status === "success") {
                      //window.location.href = data.link;
                      setStep(3);
                      setLink(data.link);
                    }
                    else {
                      message.warn(t("regError"))
                    }
                  })

                }}
              >
                <Form.Item
                  label={t('name')}

                  name='name'
                >
                  <Input defaultValue={name} value={name} readOnly />
                </Form.Item>

                <Form.Item
                  name='thuid'
                  label={t('thuId')}
                >
                  <Input defaultValue={id} value={id} readOnly />
                </Form.Item>
                <Form.Item

                  name='email'
                  label={'Email'}
                >
                  <Input defaultValue={email} value={email} readOnly />
                </Form.Item>

                <Form.Item
                  label={t('gender')}
                  name={'gender'}
                  rules={[{ required: true, message: t("genderError") }]}
                >
                  <Select placeholder={t('gender')}>
                    <Option value="Male">{t("male")}</Option>
                    <Option value="Female">{t("female")}</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t('school')}
                  name="school"
                  rules={[{ required: true, message: t("schoolError") }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t('qqId')}
                  name="inputQQID"
                  rules={[{ required: true, message: t("qqNeeded") }, { pattern: /[\d]+/, message: t("qqNeeded") }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t('phone')}
                  name="phone"
                  rules={[{ required: true, message: t("phoneErr") }, { pattern: /[\d]+/, message: t("phoneErr") }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t('next')}
                  </Button>
                </Form.Item>
              </Form>
            }{step === 3 && <>
              {t("QRCode")}<br />
              <Image
                width={200}
                src={homeQRcode}
              />
              <a href={link}>点击入群</a>
            </>}
          </Card>

        </Col>
      </Row>

    </div>
  )
}
export default Registry;