/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import homeBackground from "../../assets/2025Spring/back.jpg";
import homeLogo from "../../assets/2025Spring/logo.png";
import { useTranslation,  } from 'react-i18next'
import cssStyle from "./index.module.css";
import axios from "axios"
import { Modal,  Select } from "antd";
import { useHistory } from "react-router-dom";
const { Option } = Select;
export const Homepage: React.FunctionComponent = () => {
  let { t, i18n } = useTranslation()
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [name, setName] = useState("");
  const [id, setId] = useState(0);
  const [a, setA] = useState(0);
  const [b, setB] = useState(0);
  const [g, setG] = useState(0);
  const [touch, setTouch] = useState(0);
  const [link, setLink] = useState("");
  const [step, setStep] = useState(0);
  const [showIntro, ssIntro] = useState<boolean>(false);
  const [showTerm, ssTerm] = useState<boolean>(false);
  const history = useHistory();
  
  // const [homeImg, sethomeImg] = useState("");
  // const homeFs = [homeQF,homeTZ,homeZJ]
  const [nums, setNums] = useState(-1);
  useEffect(() => {
    // sethomeImg(homeFs[Date.now() % 3]);
    window.addEventListener('mousemove', function (e) {
      setX(e.clientX);
      setY(e.clientY);
      setId(1);
    })
    if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
    
      // Modal.info({
      //   content: t("gyo"), onOk() {
      //     (DeviceOrientationEvent as any).requestPermission().then((permissionState: any) => {
      //       if (permissionState === 'granted') {
      //         window.addEventListener('deviceorientation', function (event) {
      //           if (!id) {
      //             const beta = event.beta,
      //               gamma = event.gamma;
      //             setY(((beta || 0) / 40 - 1) * window.innerHeight);
      //             setX(((gamma || 0) / 50) * window.innerWidth);
      //           }
      //         }, false);
      //       }
      //     })
      //   }
      // })
      } else if (window.DeviceOrientationEvent) {
        window.addEventListener('deviceorientation', function (event) {
          if (!id) {
            const beta = event.beta,
              gamma = event.gamma;
            setY(((beta || 0) / 40 - 1) * window.innerHeight);
            setX(((gamma || 0) / 50) * window.innerWidth);
          }
        }, false);
      }
      
      axios.get("/api/friend/uncertain").then(({ data }) => {
        setNums(data.num);
      })
  }, [])
  
  return (
    <div style={{
      height: "100vh",
      width: "100vw",
      overflow: "hidden !important",
      
    }}
    onClick={
      () => {
        history.push("/reg")
      }
    }
    onTouchEnd={() => {
      
        history.push("/reg")
      
    }}
    >
      <div
        className={cssStyle.mainBackground}

        style={{
          backgroundImage: `url(${homeBackground})`,
          left: `calc(${x}px /40  - 100vw / 40)`,
          top: `calc(${y}px /40  - 100vh / 40)`,
          zIndex:-1000
        }}
      >
      </div>


      
      <div
          className={cssStyle.title}

        style={{
          // left:`10vw`,
          // zIndex:0,
          // backgroundColor: "rgba(255, 255, 255, .5)",
        }}
      >
    {/*<p
      style={{
            borderRadius: "5px",
            margin: "5px",
            fontSize: "max(2.5vh, 1.5vw)"
      }}
    >
      已经有 {nums} 位 <b><ruby>社<rt>bài</rt> </ruby> <ruby>友<rt>quǎn</rt></ruby></b> 入社辣！
    </p>*/}
      <div
        style={{
          backgroundImage: `url(${homeLogo})`,
          width: "80vw",
          height: "30vh",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      >
      </div>

      </div>

      <div style={
        {
          width: "100vw",
          bottom: "0",
          position: "absolute",
          zIndex:500
        }
      }>

        <div style={{ margin: "0 auto", width: "fit-content" }}><a href="https://beian.miit.gov.cn/">京ICP备2021029154号-1</a></div>
      </div>

    </div>
  )
}
export default Homepage;